// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'THNDR',
  appId: 'com.thndr.app',
  clientId: '424847802839-o0kgm9o5abute55fc9v4503629t35943.apps.googleusercontent.com',
  youtube: 'AIzaSyAUTcelku0FRVHziwqzy-RU3rjhPpOTiYA',
  createCust: 'https://thndr.apps4artists.uk/backend/create-stripe-customer',
  apiKey: 'AIzaSyAUTcelku0FRVHziwqzy-RU3rjhPpOTiYA',
  channel: 'UC6G9yjFn3LVJ7MfExL4YU2Q',
  podcast: 'PLnRlWDKv1d2WoJmUChINQt4Cg2rFGi-Mc',
  live: 'PLDQNB-RweQOPu6PdfGzPizTTzSDjHN5Rk',
  musicVids: 'PLDQNB-RweQOOlSVXnzWJDiZa7FXf6InRo',
  swag: 'PLDQNB-RweQOPdBWKdFlCYfTq6f6psyA9z',
  stripe: 'pk_live_51HFk4zJd3fzjfrRu7Rlrq6gDFX0uhuMD4e6r0urJtHioSvL0knYulOQuEnXctbOzRAdvf9PZ7JdnZ8lTRUTWtOnF00CrTxPVVu',
  firebase: {
    apiKey: 'AIzaSyDVSj8PWDrG9gas2EV1CsD33XjUrhfNtdI',
    authDomain: 'thndr-a25c8.firebaseapp.com',
    projectId: 'thndr-a25c8',
    storageBucket: 'thndr-a25c8.appspot.com',
    messagingSenderId: '836770624848',
    appId: '1:836770624848:web:1475ee5e2bb6456624e855',
    measurementId: 'G-RRK8NCFMBK'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
