import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { MusicPlayer } from '../core/musicPlayer.services';

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.component.html',
  styleUrls: ['./messages-modal.component.scss']
})
export class MessagesModalComponent implements OnInit {
  @Input() messages: string;
  constructor(private modalCtrl: ModalController, private router: Router, private music: MusicPlayer) {}

  ngOnInit() {}

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  open(notification) {}

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
