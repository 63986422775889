import { Injectable, NgZone } from '@angular/core';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AlertController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import axios from 'axios';

import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { FirebaseAuthentication, SignInResult, User } from '@capacitor-firebase/authentication';
import { GlobalVariable } from '../globals';
import { doc, getDoc } from 'firebase/firestore';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

export interface person {
  displayName: string;
  tier?: string;
  uid: string;
  email: string | null;
  created?: string;
  stripeId?: string;
  favorites: any[];
  subEnd?: any;
}

export interface UserInfo {
  name: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // user: Observable<any>;
  private userSubject: BehaviorSubject<person | null> = new BehaviorSubject<person | null>(null);
  private tierSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  public user: person;
  currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  db: any;
  auth: any;

  constructor(
    private alertController: AlertController,
    private globals: GlobalVariable,
    private navCtrl: NavController,
    private router: Router,
    private ngZone: NgZone
  ) {
    const addDocumentSnapshotListener = async () => {
      const callbackId = await FirebaseFirestore.addDocumentSnapshotListener(
        {
          reference: 'users/Aorq09lkt1ynbR7xhTUx'
        },
        (event, error) => {
          if (error) {
            console.error(error);
          } else {
          }
        }
      );
      return callbackId;
    };
  }

  getId() {
    return this.user.uid;
  }

  setFireDeets(db: any, auth: any) {
    this.db = db;
    this.auth = auth;
  }

  signInUI(user): Observable<any> {
    return this.db.doc(`users/${user.user.uid}`).valueChanges();
  }

  async addDocs(ref, data) {
    await FirebaseFirestore.addDocument({
      reference: ref,
      data: data
    });
  }

  async setDocs(ref, data) {
    await FirebaseFirestore.setDocument({
      reference: ref,
      data: data,
      merge: true
    });
  }

  async signIn(credentials): Promise<SignInResult> {
    try {
      const result: SignInResult = await FirebaseAuthentication.signInWithEmailAndPassword({
        email: credentials.email,
        password: credentials.password
      });

      const details = await result;

      this.redirectEmail(details);
      return result;
    } catch (error) {
      console.error('Email sign-in error:', error);
      throw error;
    }
  }

  async redirectEmail(result: any) {
    const user = await result;
    if (user && user.user != null) {
      try {
        const verified = user.user.emailVerified ? true : false;
        if (!verified) {
          const email = await FirebaseAuthentication.sendEmailVerification();

          const date = new Date();
          const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
          const longFormat = date.toLocaleString('en-US', options);

          const newUser = {
            displayName: user.user.displayName,
            uid: user.user.uid,
            email: user.user.email,
            created: longFormat,
            tier: 'Free',
            favorites: []
          };
          this.user = newUser;
          this.SetUserFire(newUser).then(() => {
            this.createStripeCustomer(newUser);
            this.presentVerificationPopup();
          });
        } else {
          this.importUser(user.user.uid);
        }
        this.setLogged();
        return result;
      } catch (error) {
        console.error('Error getting redirect result:', error);
        throw error;
      }
    }
  }

  async importUser(uid) {
    const docRef = `users/${uid}`; // Corrected line
    const docSnap = this.getDocument(docRef);
    const user = await docSnap;
    if (user) {
      const usery = {
        displayName: user.data.displayName,
        uid: user.id,
        created: user.data.created,
        email: user.data.email,
        tier: user.data.tier,
        favorites: user.data.favorites,
        stripeId: user.data.stripeId,
        subEnd: user.data.subEnd
      };
      this.setUser(usery);
    } else {
      // docSnap.data() will be undefined in this case
    }
  }

  async getDocument(ref: string) {
    if (Capacitor.getPlatform() == 'android') {
      const docRef = doc(this.db, ref);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
      }
    } else {
      const { snapshot } = await FirebaseFirestore.getDocument({
        reference: ref
      });
      return snapshot;
    }
  }

  async SetUserFire(user: any) {
    const userRef = `users/${user.uid}`;
    this.setDocs(userRef, user);
    this.setUser(user);
  }

  setUserAuto(user: person) {
    this.user = user;
    this.userSubject.next(user);
    this.tierSubject.next(user.tier);
  }

  setUser(user: person) {
    let date = new Date();
    if (user.tier == 'concert' && user?.subEnd < date) {
      user.tier = 'Free';
    }
    this.user = user;
    this.userSubject.next(user);
    this.tierSubject.next(user.tier);
    if (user) {
      localStorage.setItem('user', JSON.stringify(this.user));
      JSON.parse(localStorage.getItem('user')!);
    } else {
      localStorage.setItem('user', 'null');
      JSON.parse(localStorage.getItem('user')!);
    }

    this.router.navigate(['/tabs']);
  }

  async signUp(credentials) {
    try {
      const result: SignInResult = await FirebaseAuthentication.createUserWithEmailAndPassword({
        email: credentials.email,
        password: credentials.password
      });
      // // 2. Sign in on the web layer using the id token
      // const auth = getAuth();
      // const creds = await signInWithEmailAndPassword(auth, sign.email, sign.password);

      const date = new Date();
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
      const longFormat = date.toLocaleString('en-US', options);

      this.user = {
        displayName: credentials.fName + '' + credentials.lName,
        uid: result.user.uid,
        created: longFormat,
        email: credentials.email,
        tier: 'Free',
        favorites: []
      };
      const email = await FirebaseAuthentication.sendEmailVerification();

      this.SetUserFire(this.user).then(() => {
        this.createStripeCustomer(this.user);
        this.presentVerificationPopup();
      });

      return result;
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }

  getTier(): Observable<string | null> {
    return this.tierSubject.asObservable();
  }

  getUser() {
    return this.user;
  }

  getUser2(): Observable<person> {
    return this.userSubject.asObservable();
  }

  async forgot(email) {
    await FirebaseAuthentication.sendPasswordResetEmail({
      email: email
    });
  }

  async signOut() {
    FirebaseAuthentication.signOut().then(() => {
      localStorage.setItem('isLoggedIn', 'false');
    });
  }

  async presentVerificationPopup() {
    const alert = await this.alertController.create({
      header: 'Verification Email Sent',
      message: 'Please check your email(and spam) for a verification link. After verifying, please restart the app.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            // Add any necessary code to restart the app here
          }
        }
      ]
    });

    await alert.present();
  }

  async signInWithGoogle() {
    try {
      const result: SignInResult = await FirebaseAuthentication.signInWithGoogle({
        //mode: "redirect"
      });
      const details = await result;
      this.handleRedirect(details);
      return result;
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }
  async updateUser(uid: string, data) {
    const snapshot = await FirebaseFirestore.updateDocument({
      data: data,
      reference: `users/${uid}`
    });
  }

  async handleRedirect(result: any) {
    const user = await result;
    if (user && user.user != null) {
      //this.presentLoading()

      try {
        const isNewUser = user.additionalUserInfo.isNewUser ? true : false;
        if (isNewUser) {
          const date = new Date();
          const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
          const longFormat = date.toLocaleString('en-US', options);
          const newUser = {
            uid: user.user.uid,
            displayName: user.user.displayName,
            email: user.user.email,
            created: longFormat,
            tier: 'Free',
            favorites: []
          };
          this.user = newUser;
          this.SetUserFire(newUser).then(() => {
            this.createStripeCustomer(newUser);
          });
        } else {
          this.importUser(user.user.uid);
        }

        this.setLogged();
        //this.isLoggedIn(result.user);
        return result;
      } catch (error) {
        console.error('Error getting redirect result:', error);
        throw error;
      }
    }
  }

  async createStripeCustomer(user: any) {
    let link = environment.createCust;

    axios
      .post(link, { user })
      .then((data) => {
        console.log(data);
        this.updateStripeId(data.data.stripeId);
      })
      .catch((error) => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });
  }

  updateStripeId(stripe: string) {
    // updates
    if (this.user) {
      // const ref = `users/${this.user.uid}`;
      // const data = { stripeId: stripe }
      //this.setDocs(ref, data)
      this.user.stripeId = stripe;
    } else {
      console.error('User not set. Cannot update Stripe ID.');
    }
  }

  setLogged() {
    localStorage.setItem('isLoggedIn', 'true');
  }

  async deleteUser() {
    const ref = `users/${this.user.uid}`;
    const result = await FirebaseAuthentication.deleteUser();
    const snapshot = await FirebaseFirestore.deleteDocument({
      reference: ref
    });
  }

  async signInWithFacebook() {
    try {
      const result: SignInResult = await FirebaseAuthentication.signInWithFacebook({
        //mode: "redirect"
      });
      const details = await result;
      this.handleRedirect(details);
      return result;
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }

  async signInWithApple() {
    try {
      const result: SignInResult = await FirebaseAuthentication.signInWithApple({
        //mode: "redirect"
      });
      const details = await result;
      this.handleRedirect(details);
      return result;
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }
}
