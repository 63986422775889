// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments-section {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 156px; /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.comments-list {
  list-style-type: none;
  padding: 0;
}

.comment {
  display: flex;
  margin-bottom: 15px;
}

.user-avatar {
  height: 40px;
  border-radius: 50%;
}

.comment-content {
  margin-left: 10px;
}

.comment-text {
  margin: 0;
  color: white;
}

.comment-metadata {
  font-size: 0.8rem;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/app/comments-modal/comments-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,iBAAA,EAAA,wCAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;AACF","sourcesContent":[".comments-section {\n  border: 1px solid #ccc;\n  padding: 10px;\n  max-height: 156px; /* Adjust the maximum height as needed */\n  overflow-y: auto;\n}\n\n.comments-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.comment {\n  display: flex;\n  margin-bottom: 15px;\n}\n\n.user-avatar {\n  height: 40px;\n  border-radius: 50%;\n}\n\n.comment-content {\n  margin-left: 10px;\n}\n\n.comment-text {\n  margin: 0;\n  color: white;\n}\n\n.comment-metadata {\n  font-size: 0.8rem;\n  color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
