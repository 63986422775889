import { Injectable } from '@angular/core';

const STORAGE_KEY = 'tier';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }



  getData() {
    return localStorage.getItem(STORAGE_KEY);
    // return this.storage.get(STORAGE_KEY) || [];
  }

  getRole() {
    return localStorage.getItem(STORAGE_KEY);
    //return this.storage.get(STORAGE_KEY);
  }

  async addData(item) {
    return localStorage.setItem(STORAGE_KEY, item);
    //return this.storage.set(STORAGE_KEY, item);
  }

  async removeTier() {
    //localStorage.removeItem(STORAGE_KEY);
    localStorage.clear();
  }
}
