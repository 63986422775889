// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: rgb(0, 0, 0) !important;
}
ion-content .player-container {
  display: flex;
  height: 100%;
}
ion-content .player-container iframe {
  width: 100%;
  height: 380px;
  margin: auto;
}

ion-card {
  width: 900px;
}

.close {
  height: 26px;
  width: 26px;
  color: white;
  font-size: 25px;
  border: 0.5px white solid;
  position: absolute;
  right: 8vw;
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/vid-modal/vid-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;AACJ;AACI;EACE,WAAA;EACA,aAAA;EACA,YAAA;AACN;;AAMA;EACE,YAAA;AAHF;;AAMA;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EAEA,UAAA;EACA,kBAAA;AALF","sourcesContent":["ion-content {\n  --background: rgb(0, 0, 0) !important;\n\n  .player-container {\n    display: flex;\n    height: 100%;\n\n    iframe {\n      width: 100%;\n      height: 380px;\n      margin: auto;\n    }\n\n  }\n\n}\n\nion-card {\n  width: 900px;\n}\n\n.close {\n\n  height: 26px;\n  width: 26px;\n  color: white;\n  font-size: 25px;\n  border: 0.5px white solid;\n  position: absolute;\n\n  right: 8vw;\n  border-radius: 2px;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
