import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Subject } from 'rxjs';
import { MusicPlayer } from '../core/musicPlayer.services';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  messages: PushNotificationSchema[] = [];
  messagesSubject: Subject<PushNotificationSchema[]> = new Subject<PushNotificationSchema[]>();
  constructor(private router: Router, private music: MusicPlayer) {}

  initPush() {
    Device.getInfo().then((info) => {
      if (info.platform !== 'web') this.registerPush();
    });
  }

  private registerPush() {
    console.log('fcm');
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('My token: ' + token);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('Push received: ' + notification);
      this.messages.push(notification); // Add the received message to the array
      this.messagesSubject.next(this.messages); // Emit the updated array
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const data = notification.notification.data;
      if (data.album) {
        const ref = `discography/${this.toCamelCase(data.album)}`;
        const album = this.music.getDocument(ref);
        const titleEscaped = encodeURIComponent(data.album);
        this.router.navigateByUrl(`/tabs/music/${titleEscaped}`, { state: { album: album } });
      } else if (data.video) {
        this.router.navigateByUrl(`/tabs/videos`);
      } else if (data.concert) {
        this.router.navigateByUrl(`/tabs/profile`);
      }
      PushNotifications.removeDeliveredNotifications(data);
    });
  }
  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }
}
