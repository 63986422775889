import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MediaCapture, MediaFile, CaptureError, CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { Filesystem, Directory, FileInfo, Encoding, ReadFileResult, } from '@capacitor/filesystem';
import { getDownloadURL, getStorage, ref, updateMetadata, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Capacitor } from '@capacitor/core';



@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
})

export class StoryComponent implements OnInit {
  recordChunks: Blob[];


  constructor(private cd: ChangeDetectorRef, private zone: NgZone, private mediaCapture: MediaCapture, private streamingMedia: StreamingMedia, private file: File) { }
  selectedFile: string | null = null;
  totalStories: number = 0;
  video: any;
  copiedFilePath: any;
  copiedFileContent: any;
  ngOnInit() { }

  // async uploadVideo() {
  //   if (this.selectedFile) {
  //     try {
  //       // Convert the file to a data URI
  //       const dataUri = await this.convertFileToDataUri(this.selectedFile);

  //       // Now, you can use the data URI as needed
  //       console.log('Data URI:', dataUri);

  //       // For example, you can send it to Firebase Storage
  //       const storagePath = 'stories/' + this.selectedFile.name;
  //       await this.uploadDataUriToStorage(dataUri, storagePath);

  //       console.log('Video uploaded successfully!');
  //     } catch (error) {
  //       console.error('Error uploading video:', error);
  //     }
  //   } else {
  //     console.warn('No file selected.');
  //   }
  // }


  async browserCapture() {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    const recorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
    const chunks: Blob[] = [];
    recorder.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        chunks.push(event.data);
        this.zone.run(() => {
          this.recordChunks = [...chunks];
          this.cd.markForCheck();
        });
      }
    };
    recorder.onstop = () => {
      this.zone.run(async () => {
        stream.getTracks().forEach(t => t.stop());
        if (chunks?.length) {
          // Upload the Blob to Firebase Storage
          const videoData = new Blob(chunks, { type: 'ogg' })
          const storage = getStorage();
          const storageRef = ref(storage, 'stories/' + this.totalStories);
          const result = await uploadBytes(storageRef, videoData);
          console.log(result)
          //  this.upload(new Blob(chunks, {type: 'ogg'})); // use the blob result
        }
      });
    };
    recorder.start(500);
  }

  async startRecording(): Promise<void> {
    const mediaCapture = new MediaCapture();
    try {
      const opt: CaptureVideoOptions = { limit: 1, duration: 60 };
      const data = await mediaCapture.captureVideo(opt);


      // Use the full path of the captured video directly
      const videoPath = data[0].fullPath;
      let window: any;


      window.resolveLocalFileSystemURL(videoPath, fileEntry => {
        fileEntry.file(file => {
          const reader = new FileReader();

          reader.onloadend = async () => {
            const blob = new Blob([reader.result], { type: 'video/mp4' }); // or the correct video type
            console.log(blob)
            const storage = getStorage();
            const storageRef = ref(storage, 'stories/' + new Date());
            const result = await uploadBytes(storageRef, blob);

            console.log(result);

            // Do something with the blob
          };

          reader.onerror = (e) => {
            console.error('FileReader error: ', e);
          };

          reader.readAsArrayBuffer(file);
        });
      })

      // // Read the video file as an ArrayBuffer
      // const fileResult: ReadFileResult = await Filesystem.readFile({
      //   path: videoPath,
      //   directory: Directory.Data,
      // });

      // // Create a Blob from the ArrayBuffer
      // const videoBlob = new Blob([fileResult.data], { type: 'video/quicktime' });

      // Upload the Blob to Firebase Storage
      // const storage = getStorage();
      // const storageRef = ref(storage, 'stories/' + this.totalStories);
      // const result = await uploadBytes(storageRef, videoBlob);

      // console.log(result);
    } catch (e) {
      console.error(e, "video capture error");
    }
  }

  recordVideo() {



    const options: CaptureVideoOptions = {
      limit: 1,
      duration: 30,
    };

    this.mediaCapture.captureVideo()
      .then(async (mediaFiles: MediaFile[]) => {
        const capturedVideo = mediaFiles[0];
        const videoUri = capturedVideo.fullPath;
        this.video = capturedVideo;
        let dir = this.video.localURL.split('/');
        dir.pop();
        let fromDir = dir.join('/');
        let toDir = this.file.dataDirectory;
        const response = await this.file.copyFile(fromDir, this.video.name, toDir, this.video.name);
        console.log(response);
        const fileResult: ReadFileResult = await Filesystem.readFile({
          path: videoUri,
          directory: Directory.Data,
        });
        const blob = new Blob([mediaFiles[0].fullPath], { type: 'video/quicktime' });
        this.makeFileIntoBlob(blob)
        //this.selectedFile = response.nativeURL


      }


      )






      .catch((error: CaptureError) => {
        console.error('Error capturing video:', error);
      });
  }

  async makeFileIntoBlob(blob: Blob) {
    try {
      const fileName = new Date().getTime() + '.mp4';
      this.selectedFile = fileName
      const base64Data = await this.convertBlobToBase64(blob) as string;
      const savedFile = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Data
      });

      // Add file to local array
      // this.videos.unshift(savedFile.uri);

    } catch (err) {
      console.log("error: ", err)
    }



  }

  private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  async uploadVideoToFirebase(filePath?: any) {

    // Implement your Firebase Storage upload logic here
    // You can use the filePath and fileContent to upload the video to Firebase Storage
    // For example:
    const audioFile = await Filesystem.readFile({
      path: `${this.selectedFile}`,
      directory: Directory.Data,
    });
    console.log(audioFile)
    const storage = getStorage();
    const storageRef = ref(storage, 'stories/' + this.totalStories);
    const blob = new Blob([audioFile.data], { type: 'video/quicktime' });
    await uploadBytes(storageRef, blob, { contentType: 'video/mov' });
    console.log("sent")
  }

  async uploadVideo() {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, 'stories/' + this.totalStories + '.mov');
      console.log(this.selectedFile)
      // Read the video file as a data URL
      const videoFile = await Filesystem.readFile({
        path: `video1.mov`,
        directory: Directory.Data,
      });

      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataUrl = reader.result as string;

        // Create file metadata to update
        const metadata = {
          cacheControl: 'public,max-age=300',
          contentType: 'video/mov', // Set the correct content type for your video
        };

        // Convert data URL to Blob
        const byteCharacters = atob(dataUrl.split(',')[1]);
        const byteArrays = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([byteArrays], { type: 'video/mov' });

        // Upload the Blob to Firebase Storage
        const snapshot = await uploadBytes(storageRef, blob, metadata);
        this.totalStories++
      };

      reader.readAsDataURL(new Blob([videoFile.data], { type: 'video/mov' }));
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  }
}
