import { ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { IonRange, RangeCustomEvent } from '@ionic/angular';
import { GlobalVariable } from '../globals';
import { Media, MediaObject } from '@ionic-native/media/ngx';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Capacitor, Plugins } from '@capacitor/core';
import { Platform, LoadingController, ToastController } from '@ionic/angular';
import Vibrant from 'node-vibrant/dist/vibrant';
import { AuthService, person } from '../services/auth.service';
const { App, BackgroundTask } = Plugins;
import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { NativeAudio } from '@capacitor-community/native-audio';
import { environment } from 'src/environments/environment';
import { last } from 'rxjs';
import emojiFlags from 'emoji-flags';
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore';
// import { CapacitorMusicControls } from 'capacitor-music-controls-plugin';

export interface Album {
  art: string;
  artist: string;
  isFavorite?: boolean;
  name: string;
  released: string;
  tracks: Track[]; // Assuming each item in the array is an object of type Track
  type: string;
  year?: number;
  showing: boolean;
}

export interface Concert {
  city: string;
  venue: string;
  date: string;
  time: string;
  ticketLink: string;
  tickets: string[];
  showing: boolean;
  address: string;
  sold: boolean;
}

export interface Track {
  title: string;
  artists: string;
  url: string;
  duration?: number;
  progress?: number;
  position?: number;
  isFavorite?: boolean;
  lyrics?: string;
  art?: string;
  likes: string[];
  comments: comment[];
}

export interface comment {
  comment: string;
  userName: string;
  userFlag: string;
  date: string;
}

export interface likedList {
  song: Track;
  art: string;
  album: string;
  duration?: number;
}

@Injectable()
export class MusicPlayer implements OnInit {
  private addStreamTimer;
  albumAudios = [];
  currAlbum = [];
  favMode: boolean = false;
  private isFunctionCalledAgain = false;
  public activeTrack: Track = null;
  city: string;
  country: string;
  file: MediaObject = null;
  player: MediaObject = null;
  sound: HTMLAudioElement = null;
  newValue: any;
  isPlaying = false;
  duration = 0;
  progress = 0;
  value = 0;
  countryCode = '';
  public currentTrackIndex = null;
  audio: HTMLAudioElement;
  position = 0;
  dur = 0;
  currentPosition = 0;
  @ViewChild('range', { static: false }) range: IonRange;
  fileLengthChange: any;
  get_duration_interval: any;
  display_duration: any = '00:00';
  is_ready: boolean;
  get_position_interval: any;
  display_position: any = '00:00';
  curr_playing_file: MediaObject;
  public hasPrev: boolean;
  hasNext: boolean;
  proggy: any;
  user: person;
  posi: any;
  mainColor = null;
  state: string;
  album: any;
  asset: string;
  public favoriteList: likedList[] = [];
  favTrack: Track[] = [];
  discography: any;

  constructor(
    private toastController: ToastController,
    public platform: Platform,
    private media: Media,
    public globals: GlobalVariable,
    private auth: AuthService
  ) {
    this.auth.getUser2().subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit() {}

  async updateFav(album, tracks) {
    await FirebaseFirestore.updateDocument({
      reference: `discography/${album}`,
      data: {
        tracks: tracks
      }
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    return `${monthNames[monthIndex]} ${day}`;
  }

  // Function to check if the concert date is not past the current date
  isFutureDate(dateString: string): boolean {
    const today = new Date();
    const concertDate = new Date(dateString);
    return concertDate.getTime() > today.getTime();
  }

  async getConcerts() {
    const { snapshots } = await FirebaseFirestore.getCollection({
      reference: 'concerts',
      compositeFilter: {
        type: 'and',
        queryConstraints: [
          {
            type: 'where',
            fieldPath: 'showing',
            opStr: '==',
            value: true
          }
        ]
      },
      queryConstraints: [
        {
          type: 'orderBy',
          fieldPath: 'date',
          directionStr: 'asc'
        }
      ]
    });

    let concerts = [];
    snapshots.forEach((show) => {
      if (this.isFutureDate(show.data.date)) {
        const day: Concert = {
          city: show.data.city,
          venue: show.data.venue,
          date: this.formatDate(show.data.date),
          time: show.data.time,
          ticketLink: show.data.ticketLink,
          tickets: show.data.tickets,
          showing: show.data.showing,
          address: show.data.address,
          sold: show.data.sold
        };

        concerts.push(day);
      }
    });
    return concerts;
  }

  async pullDiscog(id: string) {
    const { snapshots } = await FirebaseFirestore.getCollection({
      reference: 'discography',
      compositeFilter: {
        type: 'and',
        queryConstraints: [
          {
            type: 'where',
            fieldPath: 'showing',
            opStr: '==',
            value: true
          }
        ]
      },
      queryConstraints: [
        {
          type: 'orderBy',
          fieldPath: 'released',
          directionStr: 'desc'
        }
      ]
    });
    let discography = [];
    let snappy = await snapshots;
    snappy.forEach((data) => {
      discography.push(data.data);
    });

    // Iterate over each object in the discography array
    discography.forEach((item) => {
      if (item.tracks && Array.isArray(item.tracks)) {
        // Check each track in the tracks array of the current item
        item.tracks.forEach((track) => {
          // Check if the user's ID is included in the likes array of the track
          if (track.likes && track.likes.includes(id)) {
            // If the user's ID is included, push the track to the favorites array

            const fav: likedList = {
              song: track,
              album: item.name,
              art: item.art
            };
            this.favoriteList.push(fav);
          }
        });
      }
    });
    this.discography = discography;
    return discography;
  }

  // Play a specific track
  playTrack(index, track?, tempAlbum?) {
    if (this.currentTrackIndex !== null) {
      this.stopTrack(); // Stop any currently playing track
    }
    this.activeTrack = track;
    if ((this.currAlbum = [])) {
      this.currAlbum = this.albumAudios;
    }

    this.activeTrack.art = this.album.art;
    // this.setControls(track);
    this.currentTrackIndex = index;
    this.albumAudios[index].play();
    this.isPlaying = true;
  }

  // Pause the current track
  pauseTrack() {
    this.isPlaying = false;
    if (this.currentTrackIndex !== null) {
      this.currAlbum[this.currentTrackIndex].pause();
    }
  }

  findAndEditAlbum(albumName) {
    // Use Array.find() to find the album with the given name
    const foundAlbum = this.discography.find((album) => album.name === albumName);
    // Return the found album
    return foundAlbum;
  }

  // Stop the current track
  stopTrack() {
    if (this.currentTrackIndex !== null) {
      const audio = this.currAlbum[this.currentTrackIndex];
      audio.pause();
      audio.currentTime = 0;
      this.currentTrackIndex = null;
    }
  }

  // Skip to the next track
  skipNext() {
    if (this.position < 10) {
      clearTimeout(this.addStreamTimer);
    }
    if (!this.favMode) {
      if (this.currentTrackIndex !== null && this.currentTrackIndex < this.album.tracks.length - 1) {
        this.playTrack(this.currentTrackIndex + 1, this.album.tracks[this.currentTrackIndex + 1]);
      } else {
        this.playTrack(0, this.album.tracks[0]);
      }
    } else {
      if (this.currentTrackIndex !== null && this.currentTrackIndex < this.favTrack.length - 1) {
        this.isPlaying = true;
        this.album = this.findAndEditAlbum(this.favoriteList[this.currentTrackIndex + 1].album);
        this.playTrack(this.currentTrackIndex + 1, this.favTrack[this.currentTrackIndex + 1]);
      } else {
        this.isPlaying = true;
        this.album = this.findAndEditAlbum(this.favoriteList[0].album);

        this.playTrack(0, this.favTrack[0]);
      }
    }
  }

  // Skip to the previous track
  skipPrevious() {
    // Check if not in favorite mode
    if (!this.favMode) {
      if (this.currentTrackIndex !== null) {
        if (this.currentTrackIndex > 0) {
          // If it's not the first track, play the previous track
          this.play(this.album.tracks[this.currentTrackIndex - 1], this.currentTrackIndex - 1);
        } else {
          // If it is the first track, go to the last track
          const lastIndex = this.album.tracks.length - 1;
          this.play(this.album.tracks[lastIndex], lastIndex);
        }
      }
    } else {
      // In favorite mode
      if (this.currentTrackIndex !== null) {
        if (this.currentTrackIndex > 0) {
          if (this.position > 5) {
            clearTimeout(this.addStreamTimer);
            // If it's not the first track, play the previous favorite track
            this.album = this.findAndEditAlbum(this.favoriteList[this.currentTrackIndex].album);

            const audio = this.currAlbum[this.currentTrackIndex];
            audio.pause();
            audio.currentTime = 0;
            this.isPlaying = true;
            this.playTrack(this.currentTrackIndex, this.favTrack[this.currentTrackIndex]);

            this.logStream(this.favTrack[this.currentTrackIndex]);
            this.updatePosition();
            this.updateProgress();
          } else {
            // If it's not the first track, play the previous favorite track
            this.album = this.findAndEditAlbum(this.favoriteList[this.currentTrackIndex - 1].album);

            const audio = this.currAlbum[this.currentTrackIndex - 1];
            audio.pause();
            audio.currentTime = 0;
            this.isPlaying = true;
            this.playTrack(this.currentTrackIndex - 1, this.favTrack[this.currentTrackIndex - 1]);

            this.logStream(this.favTrack[this.currentTrackIndex - 1]);
            this.updatePosition();
            this.updateProgress();
          }
        } else {
          // If it is the first track, go to the last favorite track

          if (this.position > 5) {
            clearTimeout(this.addStreamTimer);
            // If it's not the first track, play the previous favorite track
            // this.album = this.findAndEditAlbum(this.favoriteList[this.currentTrackIndex-1].album);
            const audio = this.currAlbum[this.currentTrackIndex];
            audio.pause();
            audio.currentTime = 0;
            this.isPlaying = true;

            this.playTrack(this.currentTrackIndex, this.favTrack[this.currentTrackIndex]);

            this.logStream(this.favTrack[this.currentTrackIndex]);
            this.updatePosition();
            this.updateProgress();
            // this.play(this.favTrack[this.currentTrackIndex], this.currentTrackIndex-1);
          } else {
            const lastIndex = this.favTrack.length - 1;
            this.album = this.findAndEditAlbum(this.favoriteList[lastIndex].album);
            const audio = this.currAlbum[lastIndex];
            audio.pause();
            audio.currentTime = 0;
            this.isPlaying = true;
            this.playTrack(lastIndex, this.favTrack[lastIndex]);
            this.activeTrack.art = this.album.art;
            this.logStream(this.favTrack[lastIndex]);
            this.updatePosition();
            this.updateProgress();
          }
        }
      }
    }
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  addFav(fav, album) {
    this.favoriteList.push(fav);

    this.updateFav(this.toCamelCase(album.name), album.tracks);
  }

  removeFav(song, album) {
    const index2 = this.favoriteList.findIndex((item) => item.song === song);

    // Check if the song is found in the favoriteList
    if (index2 !== -1) {
      // If found, remove the song from the favoriteList array
      this.favoriteList.splice(index2, 1);
    }
    // Update backend or local storage accordingly
    this.updateFav(this.toCamelCase(album.name), album.tracks);
  }

  prepPlaylist() {
    this.albumAudios = []; // Array to store Audio objects for each track
    this.favTrack = [];
    this.favoriteList.forEach((track, index) => {
      this.favTrack.push(track.song);
      // Create a new Audio object for each track
      const audio = new Audio(track.song.url);

      // Store the Audio object in the array
      this.albumAudios[index] = audio;

      // Event listener for when metadata is loaded
      audio.addEventListener('loadedmetadata', () => {
        // Update the duration of the track in the album
        track.duration = audio.duration;
      });
    });
  }

  playPlaylist(track, selectedIndex) {
    this.currAlbum = this.albumAudios;
    this.album = this.findAndEditAlbum(track.album);
    // this.activeTrack.art = album.art;

    this.favMode = true;
    // Scenario 1: Pause/Resume the current song
    if (this.activeTrack && this.activeTrack.title === track.song.title) {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.pauseTrack(); // Pause the current song
      } else {
        this.isPlaying = true;
        this.currAlbum[this.currentTrackIndex].play(); // Resume the current song
      }
      return;
    }

    // Scenario 2: No song is playing, start the selected song
    if (this.currentTrackIndex === null) {
      this.currAlbum = this.albumAudios;
      this.playTrack(selectedIndex, track.song);
      this.isPlaying = true;
      this.logStream(track.song);
      this.updatePosition();
      this.updateProgress();
      return;
    }

    // Scenario 3: A different song is selected, stop the current and play the new one
    this.stopTrack();
    this.currAlbum = this.albumAudios;
    this.playTrack(selectedIndex, track.song);
    this.isPlaying = true;
    this.logStream(track.song);
    this.updatePosition();
    this.updateProgress();
  }

  public play(track, selectedIndex) {
    // Scenario 1: Pause/Resume the current song
    if (this.activeTrack && this.activeTrack.title === track.title) {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.pauseTrack(); // Pause the current song
      } else {
        this.isPlaying = true;
        this.currAlbum[this.currentTrackIndex].play(); // Resume the current song
      }
      return;
    }

    // Scenario 2: No song is playing, start the selected song
    if (this.currentTrackIndex === null) {
      this.currAlbum = this.albumAudios;
      this.playTrack(selectedIndex, track);
      this.isPlaying = true;
      this.logStream(track);
      this.updatePosition();
      this.updateProgress();
      return;
    }

    // Scenario 3: A different song is selected, stop the current and play the new one
    this.stopTrack();
    this.currAlbum = this.albumAudios;
    this.playTrack(selectedIndex, track);
    this.isPlaying = true;
    this.logStream(track);
    this.updatePosition();
    this.updateProgress();
  }

  public getTrack() {
    return this.activeTrack;
  }

  reset() {
    this.position = 0;
    this.isPlaying = false;
    this.globals.isPlaying = false;
    clearTimeout(this.proggy);
    clearTimeout(this.posi);
  }

  async playSnip(snip) {
    if (this.player) {
      //if playing stop curren track
      this.player.pause();
    }

    this.player = this.media.create(snip);
    this.player.play();
  }

  setAlbum(album) {
    this.favMode = false;

    this.album = album;
    this.albumAudios = []; // Array to store Audio objects for each track

    this.album.tracks.forEach((track, index) => {
      // Create a new Audio object for each track
      const audio = new Audio(track.url);

      // Store the Audio object in the array
      this.albumAudios[index] = audio;

      // Event listener for when metadata is loaded
      audio.addEventListener('loadedmetadata', () => {
        // Update the duration of the track in the album
        track.duration = audio.duration;
      });
    });
  }

  setLocal(location) {
    const apiKey = environment.youtube;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Check if the API request was successful
        if (data.status === 'OK') {
          // Extract city and country from the response

          const addressComponents = data.results[0].address_components;
          const city = addressComponents.find((component) => component.types.includes('locality'));
          const country = addressComponents.find((component) => component.types.includes('country'));
          const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'));
          this.countryCode = country ? country.short_name : null;
          this.countryCode = emojiFlags.countryCode(this.countryCode).emoji;
          this.state = state.long_name;

          if (city) {
            this.city = city.long_name;
          } else {
          }

          if (country) {
            this.country = country.long_name;
          } else {
          }
        } else {
          console.error('Geocoding API request failed:', data.status);
        }
        let location = {
          state: this.state,
          city: this.city,
          country: this.country,
          countryCode: this.countryCode
        };
        let locationString = JSON.stringify(location);
        localStorage.setItem('location', locationString);
      })
      .catch((error) => {
        console.error('Error fetching geocoding data:', error);
      });
  }

  getFlag() {
    return this.countryCode;
  }

  findAlbumAndTrackIndexBySongTitle(songTitle) {
    for (let i = 0; i < this.discography.length; i++) {
      const album = this.discography[i];
      for (let j = 0; j < album.tracks.length; j++) {
        const track = album.tracks[j];
        if (track.title === songTitle) {
          return { albumName: album.name, trackIndex: j };
        }
      }
    }
    return null; // Return null if no matching album is found
  }
  async makeComment(song, comment) {
    const result = this.findAlbumAndTrackIndexBySongTitle(song.title);
    if (result) {
      console.log('Album name:', result.albumName);
      console.log('Track index:', result.trackIndex);
      const albumRef = doc(getFirestore(), 'discography', this.toCamelCase(result.albumName));
      const album = this.discography.find((album) => album.name === result.albumName);

      // If the album is found
      if (album) {
        // Get the track index from the result
        const trackIndex = result.trackIndex;

        // Add the comment to the specified track
        if (album.tracks[trackIndex].comments) {
          album.tracks[trackIndex].comments.push(comment);
        } else {
          album.tracks[trackIndex].comments = [comment];
        }

        // Log the updated discography
        console.log('Updated discography:', this.discography);
      } else {
        console.log('Album not found in discography.');
      }
      // Atomically add a new region to the "regions" array field.
      await updateDoc(albumRef, {
        [`tracks.${result.trackIndex}.comments`]: arrayUnion(comment)
      });
    } else {
      console.log('Song not found.');
    }
    // const foundSong = album.tracks.find((track) => track.title === song.title);
    // foundSong.comments = song.comments;

    // await FirebaseFirestore.updateDocument({
    //   reference: `discography/${this.toCamelCase(this.album.name)}`,
    //   data: {
    //     tracks: this.album.tracks
    //   }
    // });
  }
  logStream(track: Track) {
    if (!this.country) {
      // Get the JSON string from local storage
      let locationString = localStorage.getItem('location');

      // Parse the JSON string into an object
      let location = JSON.parse(locationString);

      // Assign properties to variables
      this.state = location.state;
      this.city = location.city;
      this.country = location.country;

      // Now you can use state, city, and country variables as needed
    }
    let stream = {
      date: new Date(),
      country: this.country,
      city: this.city,
      state: this.state,
      user: this.user.uid,
      album: this.album.name,
      track: track.title
    };
    this.addStream(stream);
  }

  async addStream(data) {
    // Clear the existing timer if it exists
    if (this.addStreamTimer) {
      clearTimeout(this.addStreamTimer);
      this.isFunctionCalledAgain = true;
    }

    // Set a new timer for 10 seconds
    this.addStreamTimer = setTimeout(() => {
      // Your asynchronous operation (replace with actual Firestore call)
      this.isFunctionCalledAgain = false;
      this.sendStream(data);
    }, 10000); // 10000 milliseconds = 10 seconds
  }

  async sendStream(data) {
    await FirebaseFirestore.addDocument({
      reference: 'streams',
      data: data
    });
  }

  async getDocument(ref: string) {
    const { snapshot } = await FirebaseFirestore.getDocument({
      reference: ref
    });
    if (snapshot.data) {
      return snapshot;
    } else {
      return null;
    }
  }

  // setControls(track) {
  //   CapacitorMusicControls.create({
  //     track: this.activeTrack.title, // optional, default : ''
  //     artist: this.activeTrack.artists, // optional, default : ''
  //     album: this.album.name, // optional, default: ''
  //     cover: this.activeTrack.art, // optional, default : nothing
  //     // cover can be a local path (use fullpath 'file:///storage/emulated/...',
  //     // or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
  //     // or a remote url ('http://...', 'https://...', 'ftp://...')

  //     // hide previous/next/close buttons:

  //     // iOS only, all optional
  //     duration: this.duration, // default: 0
  //     elapsed: this.position, // default: 0
  //     // hasSkipForward : true, // default: false. true value overrides hasNext.
  //     // hasSkipBackward : true, // default: false. true value overrides hasPrev.
  //     // skipForwardInterval : 15, // default: 15.
  //     // skipBackwardInterval : 15, // default: 15.
  //     hasScrubbing: true, // default: false. Enable scrubbing from control center progress bar

  //     // Android only, all optional
  //     isPlaying: true, // default : true
  //     dismissable: true, // default : false
  //     // text displayed in the status bar when the notification (and the ticker) are updated
  //     ticker: 'Now playing `${this.activeTrack.title}`',
  //     // All icons default to their built-in android equivalents
  //     // The supplied drawable name, e.g. 'media_play', is the name of a drawable found under android/res/drawable* folders
  //     playIcon: 'media_play',
  //     pauseIcon: 'media_pause',
  //     prevIcon: 'media_prev',
  //     nextIcon: 'media_next',
  //     closeIcon: 'media_close',
  //     notificationIcon: 'notification'
  //   })
  //     .then(() => {
  //       // SUCCESS
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  handleControlsEvent(action) {
    console.log('hello from handleControlsEvent');
    const message = action.message;

    console.log('message: ' + message);

    switch (message) {
      case 'music-controls-next':
        this.skipNext();
        // next
        break;
      case 'music-controls-previous':
        this.skipPrevious();
        // previous
        break;
      case 'music-controls-pause':
        this.play(this.activeTrack, this.currentTrackIndex);

        // paused
        break;
      case 'music-controls-play':
        this.play(this.activeTrack, this.currentTrackIndex);

        // resumed
        break;
      case 'music-controls-destroy':
        // controls were destroyed
        break;

      // External controls (iOS only)
      case 'music-controls-toggle-play-pause':
        this.play(this.activeTrack, this.currentTrackIndex);
        // do something
        break;
      case 'music-controls-skip-to':
        this.setSongPositionFromKnob(this.progress);
        // do something
        break;
      case 'music-controls-skip-forward':
        this.skipNext();
        // Do something
        break;
      case 'music-controls-skip-backward':
        // Do something
        this.skipPrevious();
        break;

      // Headset events (Android only)
      // All media button events are listed below
      case 'music-controls-media-button':
        // Do something
        break;
      case 'music-controls-headset-unplugged':
        // Do something
        break;
      case 'music-controls-headset-plugged':
        // Do something
        break;
      default:
        break;
    }
  }

  async updatePosition() {
    let seek = this.currAlbum[this.currentTrackIndex].currentTime;
    this.activeTrack.position = seek;
    this.currentPosition = seek;
    this.position = (seek / this.activeTrack.duration) * 100 || 0;

    this.posi = setTimeout(() => {
      if (seek >= this.activeTrack.duration && this.activeTrack.duration > 0) {
        this.reset();
      } else {
        this.updatePosition();
      }
    }, 100);
  }

  ngOnDestroy() {
    this.albumAudios[this.currentTrackIndex].stop();
    this.albumAudios[this.currentTrackIndex].release();
    // this.backgroundMode.disable();
  }

  toHHMMSS(secs) {
    var sec_num = parseInt(secs, 10);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i >= 0)
      .join(':');
  }

  updateProgress() {
    //slider active with time

    this.activeTrack.progress = (this.currentPosition / this.activeTrack.duration) * 100 || 0;
    this.proggy = setTimeout(() => {
      //update every __ms
      this.updateProgress();
    }, 100);
  }

  setSongPositionFromKnob(knobValue: number) {
    if (this.currAlbum[this.currentTrackIndex] && this.currAlbum[this.currentTrackIndex].duration) {
      const songPosition = (knobValue / 100) * this.currAlbum[this.currentTrackIndex].duration;
      this.currAlbum[this.currentTrackIndex].currentTime = songPosition;
    }
  }

  onIonKnobMoveEnd(ev: any) {
    const knobValue = ev.detail.value as number;
    this.setSongPositionFromKnob(knobValue);
  }
}
