import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MusicPlayer } from '../core/musicPlayer.services';
import { NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.page.html',
  styleUrls: ['./comments-modal.page.scss']
})
export class CommentsModalPage implements OnInit {
  newComment: any;
  comments: any[];
  track: any;
  flag: string;
  constructor(private auth: AuthService, private musicPlayer: MusicPlayer, private nav: NavParams) {
    const track = this.nav.get('track');
    this.flag = this.musicPlayer.getFlag();
    console.log(track);
  }

  ngOnInit() {
    if (!this.track.comments) {
      this.track.comments = [];
    }
    this.track.comments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }
  sendComment() {
    if (this.newComment.trim() !== '') {
      const data = {
        uId: this.auth.user.uid,
        userName: this.auth.user.displayName,
        comment: this.newComment,
        date: new Date().toString(),
        userFlag: this.flag
      };
      this.track.comments.push(data);
      this.musicPlayer.makeComment(this.track, data);

      // Clear the input field after sending the comment
      this.newComment = '';
    }
  }
  getTimeElapsed(commentDate: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - new Date(commentDate).getTime();
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);

    if (diffYears > 0) {
      return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    } else if (diffMonths > 0) {
      return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    } else if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else {
      return `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`;
    }
  }
}
