// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --ion-item-background: transparent;
  color: #fff;
}
ion-item p {
  color: #949494;
}

ion-list {
  --ion-background-color: var(--ion-color-dark);
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .playing {
    padding-bottom: 71px;
  }
}

ion-title,
ion-content {
  font-family: "Spotify";
}

.album-info {
  color: #fff;
  margin-left: 11px;
  padding-top: 45px;
}
.album-info p {
  font-weight: bold;
}

.raise-content {
  margin-bottom: 71px; /* Adjust this value as needed */
}

.image-box {
  position: fixed;
  z-index: 0;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
  --ion-background-color: var(--ion-color-dark);
}
.image-box img {
  box-shadow: 29px 35px 38px rgba(0, 0, 0, 0.2);
}

ion-row {
  padding-top: 40vh;
}

ion-content {
  --ion-background-color: var(--ion-color-dark);
}

.main {
  z-index: 2;
  background: linear-gradient(var(--custombg) 20%, var(--ion-color-dark) 30%);
}`, "",{"version":3,"sources":["webpack://./src/app/album/album.page.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;AACJ;;AAGA;EACE,6CAAA;AAAF;;AAIE;EADF;IAEI,oBAAA;EAAF;AACF;;AAGA;;EAEE,sBAAA;AAAF;;AAGA;EACE,WAAA;EACA,iBAAA;EAMA,iBAAA;AALF;AACE;EACE,iBAAA;AACJ;;AAKA;EACE,mBAAA,EAAA,gCAAA;AAFF;;AAKA;EACE,eAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,6CAAA;AAFF;AAIE;EACE,6CAAA;AAFJ;;AAMA;EACE,iBAAA;AAHF;;AAMA;EAEE,6CAAA;AAJF;;AAOA;EACE,UAAA;EACA,2EAAA;AAJF","sourcesContent":["ion-item {\n  --ion-item-background: transparent;\n  color: #fff;\n\n  p {\n    color: #949494;\n  }\n}\n\nion-list {\n  --ion-background-color: var(--ion-color-dark);\n}\n\n.playing {\n  @media screen and (max-width: 375px) and (max-height: 667px) {\n    padding-bottom: 71px;\n  }\n}\n\nion-title,\nion-content {\n  font-family: 'Spotify';\n}\n\n.album-info {\n  color: #fff;\n  margin-left: 11px;\n\n  p {\n    font-weight: bold;\n  }\n\n  padding-top: 45px;\n}\n\n.raise-content {\n  margin-bottom: 71px; /* Adjust this value as needed */\n}\n\n.image-box {\n  position: fixed;\n  z-index: 0;\n  padding-left: 15%;\n  padding-right: 15%;\n  padding-top: 5%;\n  --ion-background-color: var(--ion-color-dark);\n\n  img {\n    box-shadow: 29px 35px 38px rgba(0, 0, 0, 0.2);\n  }\n}\n\nion-row {\n  padding-top: 40vh;\n}\n\nion-content {\n  // --background: linear-gradient(var(--custombg) 400px, var(--ion-color-dark) 400px);\n  --ion-background-color: var(--ion-color-dark);\n}\n\n.main {\n  z-index: 2;\n  background: linear-gradient(var(--custombg) 20%, var(--ion-color-dark) 30%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
